







































































































































import Vue from 'vue';
export default Vue.extend({
  name: 'SuccessModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    backButtonMsg: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  methods: {
    goBack(): void {
      this.$router.back();
    },
  },
});
