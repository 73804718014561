

















import Vue from 'vue';
export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: true,
    },
    onSelect: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onClick(i: number) {
      this.onSelect(i);
    },
  },
});
