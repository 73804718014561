
























import Vue from 'vue';
import BackButton from '@/components/Presenter/BackButton.vue';
import PollForm from '@/components/Presenter/PollForm.vue';
import SuccessModal from '@/components/Presenter/SuccessModal.vue';

export default Vue.extend({
  components: { SuccessModal, PollForm, BackButton },
  data() {
    return {
      showSuccessModal: false,
    };
  },

  methods: {
    submit() {
      this.showSuccessModal = true;
    },
  },
});
